import '../scss/events.scss';

import tpl_calendar from './templates/calendar.nunjucks';
import tpl_event_list from './templates/event_list.nunjucks';

import plugin from "js-plugin";

class EventCalendar {
    constructor($node) {
        this.$node = $node;
        this.$calendar_grid = $node.querySelector('[data-js-select="calendar-grid"]');
        this.$calendar_details = $node.querySelector('[data-js-select="calendar-details"]');
        this.$next_button = $node.querySelector('[data-js-select="calendar-next"]');
        this.$prev_button = $node.querySelector('[data-js-select="calendar-prev"]');
        this.$calendar_month_display = $node.querySelector('[data-js-select="calendar-month-name"]');
        this._locale = null;
        this._month = null;
        this._day = null;
        this._year = null;
        this._today = new Date();
        this._weekdays_cache = null;
        this._data_cache = {};
        this._data_base_url = $node.dataset['url'];
        this._update_data();
    }

    get month() {
        if (!this._month) {
            this._month = this._today.getMonth() + 1
        }
        return this._month
    }

    set month(new_month) {
        this._month = new_month;
    }

    get month_name() {
        return new Date(this.year, this.month - 1, this.day)
            .toLocaleDateString(this.locale, {month: 'short'})
    }

    get day() {
        if (!this._day) {
            this._day = this._today.getDate()
        }
        return this._day
    }

    set day(new_day) {
        this._day = new_day
    }

    get year() {
        if (!this._year) {
            this._year = this._today.getFullYear()
        }
        return this._year
    }

    set year(new_year) {
        this._year = new_year
    }

    get _calendar_offset() {
        const month_index = this.month - 1;
        const year = this.year;
        const first_of_month = new Date(year, month_index, 1);
        const offset = first_of_month.getDay() - 1; // minus one since our week starts on monday not sunday
        if (offset < 0) {
            return 6;
        }
        return offset;
    }

    get days_in_month() {
        return new Date(this.year, this.month, 0).getDate();
    }

    connect() {
        this.$next_button.addEventListener('click', this.next_month);
        this.$prev_button.addEventListener('click', this.prev_month);
        this.$calendar_grid.addEventListener('click', this._set_date_from_link);
        this._update();
    }

    _set_date_from_link = (event) => {
        event.preventDefault();
        const $target = event.target;
        const day = $target.dataset['day'];
        if (day) {
            this.day = parseInt(day);
            this._update();
        }
    }

    next_month = (event) => {
        event.preventDefault();
        const month_candidate = this.month + 1;
        if (month_candidate > 12) {
            this.month = month_candidate % 12;
            this.year = this.year + 1;
        } else {
            this.month = month_candidate;
        }
        this.day = 1;
        this._update_data();
    }

    prev_month = (event) => {
        event.preventDefault();
        const month_candidate = this.month - 1;
        if (month_candidate < 1) {
            this.month = 12;
            this.year = this.year - 1;
        } else {
            this.month = month_candidate;
        }
        this.day = 1;
        this._update_data();
    }

    _update = () => {
        this._render_calendar_grid();
        this._render_calendar_header();
        this._render_day_details();
    }

    get locale() {
        if (!this._locale) {
            const $html = document.querySelector('html');
            const locale = $html.getAttribute('lang');
            this._locale = locale;
        }
        return this._locale;
    }

    get _current_data_cache_key() {
        return `${this.year}-${String(this.month).padStart(2, '0')}`;
    }

    _update_data = () => {
        const data_url = new URL(
            this._data_base_url,
            `${window.location.protocol}//${window.location.host}/`
        );
        const cache_key = this._current_data_cache_key;

        data_url.search = new URLSearchParams(
            [
                ['year', this.year],
                ['month', this.month],
            ]
        ).toString();

        if (!this._data_cache.hasOwnProperty(cache_key)) {
            fetch(data_url)
                .then(response => response.json())
                .then(data => {
                    this._data_cache[cache_key] = data;
                })
                .catch((reason => {
                }))
                .finally(this._update);
        } else {
            this._update();
        }
    }

    _render_day_details = () => {
        this.$calendar_details.innerHTML = tpl_event_list.render(
            {
                day_repr: new Intl.DateTimeFormat(this.locale, {dateStyle: 'long'}).format(new Date(this.year, this.month - 1, this.day)),
                events: this.get_days_events()
            }
        );
    }

    _get_weekdays_localized = () => {
        if (!this._weekdays_cache) {
            const a_sunday = new Date(2021, 2, 7);
            this._weekdays_cache = new Array(7).fill(undefined).map(
                (value, index) => {
                    a_sunday.setDate(a_sunday.getDate() + 1)
                    return a_sunday.toLocaleDateString(this.locale, {weekday: 'short'});
                },
            )
        }
        return this._weekdays_cache
    }

    get_days_events = (day) => {
        const day_key = `${this.year}-${String(this.month).padStart(2, '0')}-${String(day ? day : this.day).padStart(2, '0')}`;
        const cache_key = this._current_data_cache_key
        try {
            return this._data_cache[cache_key][day_key]
        } catch (e) {
            return null
        }
    }

    _render_calendar_grid = () => {
        const days = new Array(this.days_in_month)
        const context = {
            weekdays: this._get_weekdays_localized(),
            month_name: this.month,
            days: [
                ...new Array(this._calendar_offset), // padding
                ...days.fill(undefined).map((value, index) => ({
                        day: index + 1,
                        month: this.month,
                        year: this.year,
                        current: index + 1 === this.day,
                        events: this.get_days_events(index + 1)
                    }),
                ),
            ],
        }
        this.$calendar_grid.innerHTML = tpl_calendar.render(
            context,
        );
    }

    _render_calendar_header = () => {
        this.$calendar_month_display.innerText = `${this.month_name} ${this.year}`;
    }
}

const EventCalendarPlugin = {
    name: 'EventCalendar',
    initializeWithSelector: ($node) => {
        const c = new EventCalendar($node);
        c.connect($node);
    }
};

// Register plugin
plugin.register(EventCalendarPlugin);