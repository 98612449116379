import plugin from "js-plugin";

class InputDateHasValue {
    constructor($node) {
        this.$node = $node;

        // Add event listener for input changes
        this.$node.addEventListener('input', this.toggleHasValue.bind(this));
    }

    toggleHasValue() {
        // Check if the input has a value
        if (this.$node.value) {
            // If it has a value, add the 'has-value' class
            this.$node.classList.add('has-value');
        } else {
            // If it doesn't have a value, remove the 'has-value' class
            this.$node.classList.remove('has-value');
        }
    }

    loaded() {
        // Check the initial value of the input
        this.toggleHasValue();
    }
}

const InputDateHasValuePlugin = {
    name: 'InputDateHasValue',
    initializeWithSelector: ($node) => {
        const c = new InputDateHasValue($node);
        c.loaded();
    }
};

// Register plugin
plugin.register(InputDateHasValuePlugin);