import plugin from "js-plugin";

const INPAGE_DATA_ATTRIBUTE = 'inpagenav'

class InPageNavigation {
    constructor($node) {
        this.$node = $node;
        this.$list = $node.querySelector('ul');
        this.SHOW_MORE = $node.getAttribute("more");
        this.SHOW_LESS = $node.getAttribute("less");
    }

    _generate_links = () => {
        const $nodes = [...document.querySelectorAll(`[data-${INPAGE_DATA_ATTRIBUTE}]`)];
        const markup = $nodes.map((value, index, array) =>
            `<li class="${index % 2 === 1 ? 'alternate-color' : ''}">
         <a href="#${value.attributes["id"].value}">${value.dataset[INPAGE_DATA_ATTRIBUTE]}</a>
       </li>`
        )
        const joinedMarkup = markup.join('');
        return {
            count: $nodes.length,
            markup: joinedMarkup,
            length: joinedMarkup.length
        }
    }

    update = () => {
        const links = this._generate_links();
        if (links.count > 0) {
            this.$list.innerHTML = links.markup;
            this._apply_show_more(links.length);
        } else {
            this.$node.remove();
        }
    }

    _apply_show_more = (count) => {
        // show only on larger lists
        if (count > 1024) {
            const buttonContainer = document.createElement('div');
            buttonContainer.className = 'show-more';

            const showMoreButton = document.createElement('button');
            showMoreButton.innerText = this.SHOW_MORE;
            showMoreButton.addEventListener('click', () => {
                this._toggle_list_items(6);
                showMoreButton.innerText = showMoreButton.innerText === this.SHOW_MORE ? this.SHOW_LESS : this.SHOW_MORE;
                if (showMoreButton.innerText === this.SHOW_MORE) {
                    buttonContainer.classList.add('collapsed');
                } else {
                    buttonContainer.classList.remove('collapsed');
                }
            });

            buttonContainer.appendChild(showMoreButton);
            this.$node.appendChild(buttonContainer);
            this._toggle_list_items(6);
            buttonContainer.classList.add('collapsed');
        }
    }

    _toggle_list_items = (maxRows) => {
        const listItems = this.$list.querySelectorAll('li');
        listItems.forEach((item, index) => {
            if (index >= maxRows) {
                item.style.display = item.style.display === 'none' ? 'inline-block' : 'none';
            }
        });
    }

    loaded() {
        this.update();
    }
}

const InPageNavigationPlugin = {
    name: 'InPageNavigation',
    initializeWithSelector: ($node) => {
        const c = new InPageNavigation($node);
        c.loaded();
    }
};

// Register plugin
plugin.register(InPageNavigationPlugin);