import plugin from "js-plugin";

const DELAY = 150

// Scroll to the filter when filter parameters are set
class ConditionalAnchor {
    constructor($node) {
        this.$node = $node;
        this.loaded($node);
    }

    loaded() {
        // Check if there are parameters in the URL
        if (window.location.search) {
            // Scroll to the node
            if (this.$node) {
                const sleep = new Promise(() => setTimeout(() => {
                    this.$node.scrollIntoView();
                }, DELAY));
            }
        }
    }
}

const ConditionalAnchorPlugin = {
    name: 'ConditionalAnchor',
    initializeWithSelector: ($node) => {
        const c = new ConditionalAnchor($node);
        c.loaded();
    }
};

// Register plugin
plugin.register(ConditionalAnchorPlugin);