import "../scss/header.scss";

import plugin from "js-plugin";

class Header {
    constructor($node) {
        this.$menu_overlay = $node.querySelector('[data-js-select="menu-overlay"]');
        this.$menu_trigger = $node.querySelector('[data-js-select="menu-trigger"]');
        this.$menu_close = $node.querySelector('[data-js-select="close-button"]');
        this.menu_visible = false
    }

    get menu_visible() {
        return this._menu_visible;
    }

    set menu_visible(new_value) {
        this._menu_visible = new_value;
        if (this._menu_visible) {
            // show menu
            this.$menu_overlay.setAttribute('aria-hidden', false);
        } else {
            // hide menu
            this.$menu_overlay.setAttribute('aria-hidden', true);
        }
    }

    connect($node) {
        this.$menu_trigger.addEventListener('click', this.toggle_menu_overlay);
        this.$menu_close.addEventListener('click', this.toggle_menu_overlay);
    }

    toggle_menu_overlay = () => {
        this.menu_visible = !this.menu_visible;
    }
}

const HeaderPlugin = {
    name: 'Header',
    initializeWithSelector: ($node) => {
        const c = new Header($node);
        c.connect($node);
    }
};

// Register plugin
plugin.register(HeaderPlugin);
