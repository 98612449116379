import "../scss/spotify_embed.scss";

import plugin from "js-plugin";

const spotify_podcast_instances = new Map();

class SpotifyEmbed {
    constructor($node) {
        this._$node = $node;
    }

    connect($node) {
        this._$node = $node;
        spotify_podcast_instances.set(this._$node, this);
    }

    loaded($node) {
        this._$podcast_overlay = this._$node.querySelector('.consent-overlay');
        this._$podcast_overlay_activate_button = this._$node.querySelector('[data-js-select="activate-button"]');
        this._$podcast = this._$node.querySelector('[data-js-select="spotify-embed"]');

        this._$podcast_overlay_activate_button.addEventListener('click', this._activate_all)
    }

    disconnect($node) {
        this._$node = null;
        this._$podcast_overlay_activate_button = null;
        this._$podcast_overlay = null;
        spotify_podcast_instances.delete(this._$node);
    }

    activate = () => {
        this._$podcast_overlay.classList.add('hide');
        this._$podcast.classList.remove('hide');
        const podcast_url = this._$podcast.dataset['src'];
        this._$podcast.setAttribute('src', podcast_url);
        this._$podcast_overlay_activate_button.removeEventListener('click', this._activate);
        this._$podcast_overlay_activate_button = null;
    }

    _activate_all = (event) => {
        this.activate();

        // activate all other players
        for (const [$node, instance] of spotify_podcast_instances) {
            if (this._$node !== $node) {
                instance.activate()
            }
        }
    }
}

const SpotifyEmbedPlugin = {
    name: 'SpotifyEmbed',
    initializeWithSelector: ($node) => {
        const c = new SpotifyEmbed($node);
        c.connect($node);
        c.loaded();
    }
};

// Register plugin
plugin.register(SpotifyEmbedPlugin);