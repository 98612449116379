import plugin from "./js-plugin";
import "scss/index.scss";

// generic imports
import "generics/inpagenavigation";
import "generics/collapsible_section";
import "generics/references";
import "generics/friendly_gendering";
import "generics/conditional_anchor";
import "generics/input_date_has_value";

// import your plugins here.
import "citation/js/citation";
import "footer/js/footer";
import "header/js/header";
import "image/js/image";
import "youtube_video/js/youtube_video";
import "spotify_embed/js/spotify_embed";
import "self_hosted_video/js/self_hosted_video";
import "rapidmail_signup/js/rapidmail_signup";
import "stage/js/stage";

import "../../events/js/index";
import "../../sfb/js/list_filters";
import "../../events/js/database_list_filters"

plugin.initialize();