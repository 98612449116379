import "../scss/youtube_video.scss";

import plugin from "js-plugin";

const youtube_video_instances = new Map();

class YouTubeVideo {
    constructor($node) {
        this._$node = $node;
    }

    connect($node) {
        this._$node = $node;
        youtube_video_instances.set(this._$node, this);
    }

    loaded() {
        this._$video_overlay = this._$node.querySelector('.consent-overlay');
        this._$video_overlay_activate_button = this._$node.querySelector('[data-js-select="activate-button"]');
        this._$video = this._$node.querySelector('[data-js-select="youtube-embed"]');

        this._$video_overlay_activate_button.addEventListener('click', this._activate_all)
    }

    disconnect($node) {
        this._$node = null;
        this._$video_overlay_activate_button = null;
        this._$video_overlay = null;
        youtube_video_instances.delete(this._$node);
    }

    activate = () => {
        this._$video_overlay.classList.add('hide');
        this._$video.classList.remove('hide');
        const video_url = this._$video.dataset['src'];
        this._$video.setAttribute('src', video_url);
        this._$video_overlay_activate_button.removeEventListener('click', this._activate);
        this._$video_overlay_activate_button = null;
    }

    _activate_all = (event) => {
        this.activate();

        // activate all other video players
        for (const [$node, instance] of youtube_video_instances) {
            if (this._$node !== $node) {
                instance.activate()
            }
        }
    }
}

const YouTubeVideoPlugin = {
    name: 'YouTubeVideo',
    initializeWithSelector: ($node) => {
        const c = new YouTubeVideo($node);
        c.connect($node);
        c.loaded();
    }
};

// Register plugin
plugin.register(YouTubeVideoPlugin);
