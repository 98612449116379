function dom_ready(callback) {
  // thanks to Timo Huovinen https://stackoverflow.com/a/7053197
  // in case the document is already rendered
  if (document.readyState!='loading') callback();
  // modern browsers
  else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
  // IE <= 8
  else document.attachEvent('onreadystatechange', function(){
      if (document.readyState=='complete') callback();
    });
}

export { dom_ready }
