import Swiper, {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css/bundle';

import "../scss/stage.scss";

import plugin from "js-plugin";

class Stage {
    constructor($node) {
        this._$node = $node;
    }

    loaded() {
        const el = this._$node.querySelector('[data-js-select="swiper"]');

        this._swiper = new Swiper(
            el,
            {
                modules: [Autoplay, Navigation, Pagination],
                autoHeight: true,
                navigation: {
                    nextEl: this._$node.querySelector('[data-js-select="swiper-next"]'),
                    prevEl: this._$node.querySelector('[data-js-select="swiper-prev"]'),
                },
                pagination: {
                    el: this._$node.querySelector('[data-js-select="swiper-pagination"]'),
                },
                speed: 500,
                loop: true,
                autoplay: {
                    delay: el.getAttribute('autoplay-delay'), // Delay between transitions (in ms)
                    // Set to false to not disable autoplay after user interactions
                    disableOnInteraction: el.getAttribute('stop-on-interaction'),
                },
                on: {
                    transitionStart: () => {
                        this._$node.querySelectorAll('video').forEach((elem) => {
                            elem.pause();
                        })
                    },
                },
            },
        );

    }
}

const StagePlugin = {
    name: 'Stage',
    initializeWithSelector: ($node) => {
        const c = new Stage($node);
        c.loaded();
    }
};

// Register plugin
plugin.register(StagePlugin);