import plugin from "js-plugin";

class References {
    constructor($node) {
        this.$node = $node;
        this.$list = this.$node.querySelector('[data-select-list]');
    }

    update = () => {
        this.$citations = [...document.querySelectorAll('[data-citation]')];
        const citations = [...this.$citations]
        const markup = citations.map(
            ($node, index) => {
                $node.querySelector('a').innerHTML = `[${index + 1}]`;
                const cite_data = JSON.parse($node.dataset['citation']);
                return `<li id="citation-footnote-${cite_data.id}">
                  ${cite_data.text}
                  <a
                      href="#citation-mark-${cite_data.id}"
                  >
                    <i class="icon icon-arrow-up"></i>
                  </a>
                  ${cite_data.link ? `
                  <a href="${cite_data.link}" rel="noopener" target="_blank">
                    <i class="icon icon-earth"></i>                  
                  </a>` : ``}
                </li>`
            }
        )
        this.$list.innerHTML = markup.join('');
        if (citations.length) {
            this.$node.style["display"] = 'block';
        } else {
            this.$node.style["display"] = 'hidden';
        }
    }

    loaded() {
        this.update();
    }
}

const ReferencesPlugin = {
    name: 'References',
    initializeWithSelector: ($node) => {
        const c = new References($node);
        c.loaded();
    }
};

// Register plugin
plugin.register(ReferencesPlugin);